<template>
  <div>
    <form onSubmit="return false;">
      <vx-card style="padding-left: 10px">
        <div class="vx-row">
          <div class="vx-col sm:w-1/3 w-full mb-3">
            <span>Suburb Name</span>
          </div>
          <div class="vx-col sm:w-1/3 w-full mb-3">
            <span>State code</span>
          </div>
          <div class="vx-col sm:w-1/3 w-full mb-3">
            <span>Postal code</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-1/3 w-full mb-3">
            <vs-input
              class="w-full"
              placeholder="Suburb"
              v-model="postalCode.name"
              data-vv-validate-on="blur"
              v-validate="'required'"
              name="Suburb"
            />
            <span class="text-danger text-sm">{{ errors.first('Suburb') }}</span>
          </div>
          <div class="vx-col sm:w-1/3 w-full mb-3">
            <vs-input
              class="w-full"
              placeholder="State Code"
              v-model="postalCode.stateCode"
              data-vv-validate-on="blur"
              v-validate="'required'"
              name="stateCode"
            />
            <span class="text-danger text-sm">{{ errors.first('stateCode') }}</span>
          </div>
          <div class="vx-col sm:w-1/3 w-full mb-3">
            <vs-input
              class="w-full"
              placeholder="Postal Code"
              v-model="postalCode.postalCode"
              data-vv-validate-on="blur"
              v-validate="{required:true,numeric:true}"
              name="postalCode"
            />
            <span class="text-danger text-sm">{{ errors.first('postalCode') }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-1/3 w-full mb-3">
            <span>Latitude</span>
          </div>
          <div class="vx-col sm:w-1/3 w-full mb-3">
            <span>Longitude</span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full mb-3">
            <vs-input
              class="w-full"
              placeholder="Latitude"
              v-model="postalCode.latitude"
              data-vv-validate-on="blur"
              v-validate="{required:true,decimal}"
              name="latitude"
            />
            <span class="text-danger text-sm">{{ errors.first('latitude') }}</span>
          </div>
          <div class="vx-col sm:w-1/3 w-full mb-3">
            <vs-input
              class="w-full"
              placeholder="Longitude"
              v-model="postalCode.longitude"
              data-vv-validate-on="blur"
              v-validate="{required:true,decimal}"
              name="longitude"
            />
            <span class="text-danger text-sm">{{ errors.first('longitude') }}</span>
          </div>
        </div>

       

        

        <div class="vx-row" style="margin-top: 30px">
          <div class="vx-col">
            <vs-button class="mr-3 mb-2" type="flat" color="danger" @click="cancel">Cancel</vs-button>
          </div>
          <div class="vx-col">
            <vs-button class="mr-3 mb-2" :disabled="!validateForm" @click="createTip">Save</vs-button>
          </div>
        </div>
      </vx-card>
    </form>


    
  </div>
</template>

<script>
  import {mapActions} from "vuex";

  import axios from "../../axios";


  export default {
   
    data() {
      return {
        postalCode: {
          id:null,
          name: "",
          stateCode: "",
          postalCode: "",
          latitude: "",
          longitude: "",
        }, 
      };
    },
    computed: {
      validateForm() {
        return !this.errors.any();
      }
    },
    mounted() {
      this.postalCode.id = this.$route.params.id;
      if(this.$route.params.id)
      {
        this.getPostalCodeDetails( this.postalCode.id ) 
      } 
    },
    methods: {
      ...mapActions("postalCode", ["fetchPostalCodeDetails","updatePostalCode"]),
      createTip() {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$vs.loading();
            this.updatePostalCode(this.postalCode)
              .then(response => {
                console.log(response);
                this.$vs.loading.close();
                this.$router.push("/admin/postcodes");
              })
              .catch(error => {
                console.log(error)
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Error",
                  text: error.message,
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "danger"
                });
              });
          }
        });
      },
      //for edit
      getPostalCodeDetails(id) {
        this.fetchPostalCodeDetails(id)
          .then(response => {
            if (response) {
                this.postalCode.name = response.data.data.name,
                this.postalCode.stateCode = response.data.data.stateCode,
                this.postalCode.postalCode = response.data.data.postalCode,
                this.postalCode.latitude = response.data.data.latitude,
                this.postalCode.longitude = response.data.data.longitude  
                console.log('tip',this.postalCode)
            }
          })
          .catch(error => {
            console.log(error)
          });
      },

  

      cancel() {
        // this.removeMaterial();
        this.$router.push('/admin/postcodes');
      },
    }
  };
</script>
